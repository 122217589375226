<script setup lang="ts">
import { SiteInformationResults } from "@/types/Facet";

const { $fireBangApiDomain } = useNuxtApp();

// お知らせ取得
const { data: fetchInfodata } = await useFetch(`${$fireBangApiDomain}/api/facet/information`, {
  key:'informationData',
  transform: (data: SiteInformationResults) => {
    const results = data ? data.results : null;
    return results;
  },
});
</script>

<template>
  <div v-if="fetchInfodata?.length" class="button-linkTopics">
    <a
      v-for="(item, index) in fetchInfodata"
      :key="index"
      href="javascript:void(0);"
      @click="$popUp.browserWin(item.url)"
      >{{ item.title }}</a
    >
  </div>
</template>

<style lang="scss" scoped>
.button-linkTopics {
  margin: 0.8em 6% 0;
  padding: 0.5em;
  border: 1px solid #ccc;
  background-color: #fff;
  text-align: center;

  a {
    margin: 0 auto;
    display: table;
    color: $colorBlue;
    font-size: px(11);
  }
}

.button-linkTopics a:hover {
  text-decoration: underline;
}

#promo {
  .button-linkTopics {
    margin: -1.5% 4% 2.5%;
    padding: 0;
    border: none;
    background-color: inherit;

    a {
      display: block;
      color: #fff;
    }
  }

  &.pageLanding03Index .button-linkTopics a {
    color: #333;
  }

  .button-linkTopics a:not(:first-child) {
    margin: 0.4em 0 0;
  }
}

@include media(pc) {
  .button-linkTopics {
    margin: 0.8em auto -27px;
    padding: 0.5em;
    width: $pcWrapWidth;

    a {
      font-size: px(12.9);

      &:not(:first-child) {
        margin: 0.4em auto 0;
      }
    }
  }

  #promo .button-linkTopics {
    margin: -20px auto 10px;

    a {
      font-size: px(13);
    }
  }
}
</style>
